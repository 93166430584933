import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';
import { useGetCompany } from 'hooks/company';
import { useGetWidgetSettings } from 'hooks/customization';
import { useAuth } from 'shared/lib/auth/useAuth';

import type { AdditionalDetailsFormData } from './AdditionalDetailsForm';

export type DetailsToAdd = {
  logo?: boolean;
  billingEmail?: boolean;
};

type UseUpdateAdditionalDetailsProps = {
  formData: AdditionalDetailsFormData;
  details: DetailsToAdd;
};

export const useUpdateAdditionalDetails = () => {
  const client = useQueryClient();
  const { user } = useAuth();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['useUpdateAdditionalDetails'],
    mutationFn: async ({ formData, details }: UseUpdateAdditionalDetailsProps) => {
      const requests = [];

      if (details.logo) {
        requests.push(formData.logo ? apiClient.uploadLogo(formData.logo) : apiClient.deleteLogo());
      }

      if (details.billingEmail && user?.company?.id) {
        requests.push(apiClient.updateCompanySettings(user.company.id, { billing_email: formData.billingEmail }));
      }

      const result = await Promise.all(requests);

      return {
        widgetSettings: details.logo && result[0],
        company: details.billingEmail && (details.logo ? result[1] : result[0]),
      };
    },
    onSuccess: ({ widgetSettings, company }) => {
      client.setQueryData(['getCompany'], () => company);
      client.setQueryData(['getWidgetSettings'], () => widgetSettings);
    },
  });

  return {
    updateDetails: mutateAsync,
    isLoading: isPending,
  };
};

export const useHasAdditionalDetails = () => {
  let detailsToAdd: DetailsToAdd = {};

  const { data: company, isPending: companyLoading } = useGetCompany();
  const { data: widgetSettings, isPending: widgetLoading } = useGetWidgetSettings();

  if (!companyLoading && !widgetLoading) {
    if (company && !company?.billing_email) {
      detailsToAdd = { ...detailsToAdd, billingEmail: true };
    }

    if (widgetSettings && !widgetSettings?.logo) {
      detailsToAdd = { ...detailsToAdd, logo: true };
    }
  }

  return {
    detailsToAdd,
    hasDetails: !!Object.keys(detailsToAdd).length,
  };
};
