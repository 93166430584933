import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { usePageVisibility } from 'react-page-visibility';

import { CompleteProfile } from 'components/CompleteProfile/CompleteProfile';
import { UnapprovedCompanyMember } from 'components/UnapprovedUser/UnapprovedCompanyMember';
import { UnapprovedUser } from 'components/UnapprovedUser/UnapprovedUser';
import { UnverifiedEmail } from 'components/UnverifiedEmail/UnverifiedEmail';
import { useSessionExpiredModal } from 'hooks/useSessionExpiredModal';
import { useAuth } from 'shared/lib/auth/useAuth';
import LoginView from 'views/auth/LoginView';
import { Error401UserView } from 'views/errors/Error401View';
import { ErrorNoAccess } from 'views/errors/ErrorNoAccess';
import { ErrorNotAuthenticated } from 'views/errors/ErrorNotAuthenticated';

type AuthGuardProps = {
  children?: ReactNode;
};

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const {
    isAuthenticated,
    wasAuthenticated,
    checkAuth,
    user,
    isGotError401,
    error,
    authFailed,
    authFailedError,
    permissions,
  } = useAuth();

  const isVisible = usePageVisibility();

  const { handleSessionExpired } = useSessionExpiredModal();

  useEffect(() => {
    if (isVisible && !isAuthenticated && wasAuthenticated) {
      handleSessionExpired();

      return;
    }

    checkAuth();
  }, [checkAuth, handleSessionExpired, isAuthenticated, isGotError401, isVisible, wasAuthenticated]);

  // this is initial login
  if (!wasAuthenticated && !authFailed) {
    return <LoginView />;
  }

  if (authFailed) {
    return <ErrorNotAuthenticated error={authFailedError} />;
  }

  if (isGotError401) {
    return <Error401UserView error={error} />;
  }

  if (!user?.isOnboardingFormComplete) {
    return <CompleteProfile showCompanyField={!!user?.is_owner} />;
  }

  if (!user?.email_verified) {
    return <UnverifiedEmail />;
  }

  if (!user?.is_owner && !!user?.company && !user?.is_approved) {
    return <UnapprovedCompanyMember declined={user?.is_approved === false} />;
  }

  if (!user?.is_approved) {
    return <UnapprovedUser />;
  }

  if (!permissions.has('can_view_client_dashboard')) {
    return <ErrorNoAccess />;
  }

  return <>{children}</>;
};
