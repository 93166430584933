import { useState } from 'react';

import { Box, Card, Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@truvhq/ui';

import { useAuth } from 'shared/lib/auth/useAuth';

import { resendVerificationEmail } from './api';
import { Footer } from './Footer';
import unverified from './images/unverified.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    width: '832px',
    padding: '32px 28px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      minWidth: '360px',
    },
  },

  imageContainer: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px',
    borderRadius: '8px',
  },

  header: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  subheader: {
    marginTop: '8px',
    padding: '0 32px',
    fontSize: '16px',
    lineHeight: '28px',
  },
  link: {
    fontSize: '16px',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    display: 'inline-block',
  },
}));

export const UnverifiedEmail = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);

  const { user } = useAuth();

  const classes = useStyles();

  function handleResendClick() {
    if (user !== null) {
      resendVerificationEmail(user.id).catch((e) => console.log(e));
      setIsEmailSent(true);
    }
  }

  return (
    <div
      style={{
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={0}
        style={{ flexGrow: 1, height: '100%' }}
      >
        <Card className={classes.container}>
          <Box className={classes.imageContainer}>
            <img alt="" src={unverified} width={280} />
          </Box>
          <Box mt={4}>
            <Typography className={classes.header} variant="h1">
              Confirm your email
            </Typography>
            <Typography className={classes.subheader} variant="body1">
              One step left! Please check your inbox for a link to verify your email address so you can begin using the
              Truv dashboard.{' '}
              {!isEmailSent && (
                <>
                  Can’t find the verification email in your inbox? Click{' '}
                  <Link className={classes.link} underline="hover" onClick={handleResendClick}>
                    here
                  </Link>{' '}
                  to resend the link.
                </>
              )}
            </Typography>

            <div>
              {isEmailSent && <div className={classes.subheader}>A second confirmation email has been sent.</div>}
            </div>
          </Box>
        </Card>
      </Grid>
      <Footer pageName="unverified email" />
    </div>
  );
};
